section p,li {
  color: #333;
}

section h2,h3 {
  color: #444;
}

section.article {
  font-size: 1.2em;
}

.navbar-brand > img {
  width: 128px;
}

.team-member {
  text-align: center !important;
}
.team-member img {
  margin: auto !important;
}

.pricing-plan {
  background: #f8f8f8;
}

.pricing-plan.selected {
  transform: scale(1.1);
  background: #f4f4f4 ;
}

.contact {
  margin-top: 60px;
}

.btn-primary {
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
}

section.timeline {
  padding-bottom: 30px;
}


#content img {
  max-width: 100%;
  box-shadow: 0px 0px 15px #888888;
  margin: 15px;
}

#content img.no-border {
  box-shadow: none;
  margin: 15px;
}

a.page-title {
  color: white;
  text-decoration: none;
}

/* Fixes the issue with anchor and fixed header - clicked anchor was not visible on jump */
/* https://stackoverflow.com/a/9618795 */
h2[id]::before {
  content:"";
  display:block;
  height:90px; /* fixed header height*/
  margin:-90px 0 0;
}

#content table, th, td {
  border-style: solid;
  border-width: 1px;
  border-color: #CCC;
}

#content th, td {
  padding: 5px;
}



.carousel-caption {
    top: 40px;
    right: 0;
    left: 0;
    /*text-align: center;*/
}

.header h1 {
  text-transform: none;
  position: relative;
}

@media (max-width: 859px) {
  .carousel-caption,
  .header {
    top: 70px;
  }
}

.carousel-caption h2 {
  margin-top: 0px;
  margin-bottom: 40px;
}

.header-back {
  height: 420px;
}

.header {
  // position: absolute;
  // right: 15%;
  // bottom: 20px;
  // left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0,0,0,.6);
}

.header-container {
  @media (min-width: 1200px) {
    width: 1170px;
    margin: auto;
  }
}

/* Declare heights because of positioning of img element */
.header .item {
  background-color: #777;
}

.header-column {
  z-index: 10;
  padding-left: 50px;
}

.header h1 {
  font-weight: 700;
  font-size: 48px;
  text-transform: none;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
  letter-spacing: -1.5px;
  margin-top: 100px;
}

.header p {
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: none;
}

.header img {
  margin-top: 50px;
}

.stars-gradient {
  background: radial-gradient(ellipse at bottom, #1c4473 0%, #22052e 100%);
}

.carousel-inner > .item > img {
  height: 420px;
}

@media (max-width: 859px) {
  .header h1 {
    // margin-top: 0px;
  }
}

@media (max-width: 599px) { /* size of subtitle */
  .header h1 {
    font-size: 38px;
  }
}

.header .below-signin-form {
  padding-top: 5px;
  font-size: 1.1em;
}

.subtitle {
  font-size: 1.5em;
  max-width: 600px;
  line-height: 1.5em;
}

.header h2 {
  margin-top: 20px;
  margin-bottom: 60px;
}

@media (max-width: 599px) {
  .header h2 {
    margin-bottom: 30px;
  }
}

.header-back.one {
  background-color: #333333;
  background-image: url("../img/index2_bg_2_a.png");
  background-position: right;
}

#header-rocket {
  width: 350px;
  height: 350px;
  min-width: 0px;
  left: 70%;
  top: 100px;
}

.header-image {
  right: 10px;
  width: 50% !important;
  height: auto !important;
  min-width: 0px !important;
  min-height: 0px !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1199px)
{
  #header-rocket {
    display: none;
  }
}

.shadow-5 {
  // -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
  // -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
  // box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
  filter: drop-shadow(0px 0px 3px #000); // this gets the PNG shadow right
}

.shadow-25 {
  -webkit-box-shadow: 0px 0px 25px 10px rgba(0,0,0,0.20);
  -moz-box-shadow: 0px 0px 25px 10px rgba(0,0,0,0.20);
  box-shadow: 0px 0px 25px 10px rgba(0,0,0,0.20);
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-15 {
  border-radius: 15px;
}

@media (max-width: 1200px)
{
  .gamesdiv {
    display: flex;
    justify-content: center;
    margin:10px;
  }
.trusted p, .trusted h2 {
  text-align: center;
}
}
@media (min-width: 1151px)
{
  .left{
    float:left;
  }
  .right-image {
    float:right;
  }
}

.trusted img {
  margin: 5px;
}

.trusted .info {
  height: 281px;
  line-height: 281px;
}

.trusted .info .cont {
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
}

.footer {
  margin-top: 0;
}

.dynamic-form-loading {
  display: none;
}

.dynamic-form-success {
  display: none;

  h2, h3, p {
    color: #EEE;
    text-shadow: 0px 0px 6px black;
  }
}

.dynamic-form-error {
  display: none;
}

.half-transparent-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  /* with soft edges */
  border-radius: 5px;
}

.overlay-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background: url(/img/close.png) no-repeat;
  background-size: 100% 100%;
  z-index: 9999;
}

.spinner-animation > .spinner{
  width: 150px;
  height: 150px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: top left;
  transform: translate(-50%,-50%);
  border-radius: 50%;
  box-shadow: inset 3px 3px 3px white;
  animation: spinner 0.6s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg) translate(-50%,-50%);
  }
  100% {
    transform: rotate(360deg) translate(-50%,-50%);
  }
}

/* Vertically and horizontally center a div of text inside another div */
.centered {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.popup-nav {
  background-color: white;
  position: absolute;
  min-width: 350px;
  // width: 400px;
  // height: 200px;

  // add proportional roudness
  border-radius: 5px;
  right: auto !important; // makes sure that the menu is not pushed to the right


  // display: flex !important;
  flex-direction: column;

  .sub-nav-item {
    display: flex;

    gap: 20px;

    pointer-events: none;
    padding: 10px;
    margin: 10px;
    border-radius: 7px;
  }

  .sub-nav-icon-container {
    min-width: 40px;
    height: 40px;
    background-color: #f5f5f5;
    display: flex;
    // center the icon
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
  }

  .sub-nav-icon {
    left: 0;
    // margin-top: 20px;
    font-size: 1.5em;
    pointer-events: none;
  }

  .sub-nav-title {
    font-size: 1.1em;
    font-weight: bold;
    // margin: 20px;
    margin-bottom: 0px;
    pointer-events: none;
  }

  .sub-nav-description {
    font-size: 0.9em;
    margin-right: 20px;
    // margin-bottom: 20px;
    font-weight: 500;
    pointer-events: none;
  }

  a:link, a:visited {
    color: #333;
    text-decoration: none;
  }

  a:hover {
    // gradient
    
    border-radius: 5px;
    div {
      background-color: #e2eeec;
    }
  }
  
}

.dropdown {
  cursor: pointer;
}

.technical-term {
  // underlined by dashed green line
  border-bottom: 1px dashed #4CAF50;
  cursor: help;

  // on hover, highlight the term
  &:hover {
    background-color: #e2eeec;
  }
}

#evaluate {
  @media (min-width: 1200px) {
    width: 1170px;
    margin: auto;
  }
}